export default defineNuxtRouteMiddleware((to) => {
  const newId = to.params.id as string;

  if (isNaN(Number(newId))) {
    throw createError({
      statusCode: 404,
      message: 'Page not found',
      fatal: true,
    });
  }
});
